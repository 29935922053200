@tailwind base;

body {
  @apply font-normal;
  @apply text-base;
  @apply bg-dte-500;
  @apply w-screen;
  @apply h-screen;
  @apply p-0;
  @apply m-0;
  @apply text-white;
  @apply absolute;
  @apply top-0;
  @apply left-0;
  padding-bottom: env(safe-area-inset-bottom);
}

@media only screen and (max-width: 768px) {
  .responsive-modal-content {
    width: 99% !important;
  }

  .hidden-768 {
    display: none;
  }
}

.badge {
  @apply rounded-full;
  @apply shadow;
  @apply text-center;
  @apply bg-red-600;
  @apply text-white;
  @apply absolute;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  top: -0.25rem;
  right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 0.25rem;
}

.badge-no-text {
  top: 0rem;
  right: -0.33rem;
  width: 0.6rem;
  height: 0.6rem;
  padding-bottom: 0.25rem;
}

.responsive-badge {
  @apply text-xs;
  @apply rounded-full;
  @apply shadow;
  @apply text-center;
  @apply bg-red-600;
  @apply text-white;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  width: 1.25rem;
  height: 1.25rem;
  padding-bottom: 0.15rem;
  padding-right: 0.15rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

/* Chrome, Safari, Edge, Opera */
.grants-specical-number-input::-webkit-outer-spin-button,
.grants-specical-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.grants-specical-number-input {
  -moz-appearance: textfield;
}

.error-control {
  text-decoration: 2px #e53e3e underline;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.text-opacity-0 {
  color: rgba(0, 0, 0, 0);
}

.shadow-main {
  box-shadow: 0px -4px 44px rgba(0, 0, 0, 0.14);
}

.responsive-modal-content {
  z-index: 50;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: auto;
}

@media only screen and (max-width: 800px) {
  .responsive-modal-da-form {
    width: 99% !important;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.mb-1-i {
  margin-bottom: 1rem !important;
}

.modal-no-padding-content {
  padding: 0 !important;
}

.esriPopupWrapper .titlePane {
  line-height: 50px;
  font-size: 12px;
}

.esriPopupWrapper .content .contentPane {
  background-color: #fff;
  padding: 6px;
}

.esriPopupWrapper a {
  color: #336699;
  text-decoration: underline;
}
.esriPopupWrapper a:hover {
  cursor: pointer;
}

button:focus {
  @apply outline-none;
}

.basemapContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0rem 1rem 6rem 1rem;
  z-index: 10;
}

.basemapTitle {
  width: 100% !important;
}

.arcgisSearch {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1.25rem;
  z-index: 10;
  margin-left: 4rem;
}

.locateContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1.25rem;
  margin-top: 5.5rem;
  z-index: 10;
}

.map-pin-label {
  color: black;
  position: absolute;
  top: -13px;
  right: -8px;
}

@tailwind components;

@tailwind utilities;

.loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
}
.loader:before {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
.loader:after {
  z-index: 2;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  border: 16px solid #3464a7;
  border-top: 16px solid rgb(255, 153, 0);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.loader.loading {
  display: block;
}
.loader.loading:before,
.loader.loading:after {
  content: "";
}

.wait-cursor {
  cursor: wait !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* react popup js */
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  .vh-work-around {
    position: fixed;
    bottom: 0;
    height: -webkit-fill-available !important;
  }
  html {
    height: -webkit-fill-available;
  }

  body {
    min-height: -webkit-fill-available;
    position: fixed;
    bottom: 0;
  }
}

.html2canvas-container {
  width: 2650px !important;
  height: 100000px !important;
}

#damage-assesment-form.uploading img {
  width: 100%;
  height: initial;
  margin-right: auto;
  margin-left: auto;
}

.nowrap {
  white-space: nowrap;
}

.wide-content {
  width: 90%;
}
